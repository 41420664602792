
import styles from "./CirclePreloader.module.scss";
import logo_full from "../../../src/images/logo_full.png"
export default function CirclePreloader(){
    return (
        <div className={styles["circle-preloader"]}>

            <div className={styles["circle-preloader__circle"]}>
                <img src={logo_full} alt="" />
                <div className={styles["circle-preloader__container"]}>
                <h1 >
                    Загрузка...
                </h1>
                </div>
                
            </div>
        </div>
    )
}