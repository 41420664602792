import ScrollContainer from 'react-indiana-drag-scroll';
import { useContext } from 'react';

import { TgContext } from '../../../settings/context';

import clipboard from "../../../images/info/clipboard.png";
import emojies from "../../../images/info/emojies.png";
import emoji from "../../../images/info/emoji.png";
import cube from "../../../images/info/cube.png";
import lock from "../../../images/info/lock.png";
import block_1 from "../../../images/block_1.png";
import block_2 from "../../../images/block_2.png";
import block_3 from "../../../images/block_3.png";
import styles from "./InfoBlock.module.scss";
import { url } from 'inspector';

export default function InfoBlock() {

    const { tgApp } = useContext(TgContext);

    const openLink = (link: string) => {
        tgApp.openLink(link, { try_instant_view: true });
    }

    const language = tgApp?.initDataUnsafe?.user?.language_code || 'en';

    return (
        <div className={styles["info-block"]}>
            <ScrollContainer className={styles["info-block__container"]}>
                <div className={styles["info-block-item"]} 
                style={{ 
                    backgroundImage: "url("+block_1+")"
                }}>
                    <p className={styles["info-block-item-text"]}>
                        
                        {(language == "en") && (
                            <>
                            Rewards
                            </>
                        )}
                        {(language == "ru") && (
                            <>
                            Награды
                            </>
                        )}
                    </p>
                </div>
                <div className={styles["info-block-item"]} 
                style={{ 
                    backgroundImage: "url("+block_2+")"
                }}>
                    <p className={styles["info-block-item-text"]}>
                        
                        {(language == "en") && (
                            <>
                            Get rewards<br/>for playing
                            </>
                        )}
                        {(language == "ru") && (
                            <>
                            Получайте награды<br/> за игру
                            </>
                        )}
                    </p>
                </div>
                <div className={styles["info-block-item"]} 
                style={{ 
                    backgroundImage: "url("+block_3+")",
                    minHeight:"120px",
                    paddingTop:"13px" 
                }}>
                    <p className={styles["info-block-item-text"]}>
                        
                        {(language == "en") && (
                            <>
                            Referral<br/>System
                            </>
                        )}
                        {(language == "ru") && (
                            <>
                            Реферальная<br/>система
                            </>
                        )}
                    </p>
                </div>
            </ScrollContainer>
        </div>
    )
}
