import { Dispatch, SetStateAction } from 'react';

import MainModule from '../components/project/MainModule/MainModule';

interface MainPageInt{
    stage: number,
    setStage: Dispatch<SetStateAction<number>>,
    toncoll: number,
    setTon: Dispatch<SetStateAction<number>>
    monetcoll: number,
    setMonetColl: Dispatch<SetStateAction<number>>
}

export default function MainPage({ stage, setStage, toncoll, setTon, monetcoll, setMonetColl }: MainPageInt) {

    const userId = 2;

    return (
        
        <MainModule userId={userId} stage={stage} setStage={setStage} toncoll={toncoll} setTon={setTon} monetcoll={monetcoll} setMonetColl={setMonetColl} />
    )
}