import axios from "axios";

import { SERVER_URL_API } from "../settings/config";
import { RequestInt } from "../settings/interfaces";

class DAS_HTTP{

    async request({
        method = "GET", 
        url, 
        data = {}, 
        hasToken = false, 
        formEnable = false, 
        checkData = false, 
        checkHeaders = false, 
        getStatus = false, 
        getData = false,
        getAll = false,
    }: RequestInt){
        return await axios({
            method: method,
            url: `${SERVER_URL_API}${url}`,
            data: (formEnable) ? data : JSON.stringify(data),
            headers: this.checkHeaders(hasToken, formEnable),
        }).then(r => {
            if (checkData){
                console.table(r.data);
            }
            if (checkHeaders){
                console.table(r.headers);
            }
            if (getStatus){
                return r.status
            }
            if (getData){
                return r.data;
            }
            if (getAll){
                return {data: r.data, status: r.status};
            }
        }).catch(e => {
            console.error("error", e);
        });
    }

    checkHeaders(hasToken: boolean = false, formEnable: boolean): any{
        if (hasToken && formEnable){
            return {
                "Content-Type": "multipart/form-data",
                "Authorization": `Token ${localStorage.getItem("token")}`,
            }
        }
        else if (!hasToken && formEnable){
            return {
                "Content-Type": "multipart/form-data",
            }
        }
        else if (!hasToken && !formEnable){
            return {
                "Content-Type": "application/json",
            }
        }
        else if (hasToken && !formEnable){
            return {
                "Content-Type": "application/json",
                "Authorization": `Token ${localStorage.getItem("token")}`
            }
        }
    }
}

export default new DAS_HTTP();