import { useTonAddress } from '@tonconnect/ui-react';
import {TonConnectButton} from "@tonconnect/ui-react";
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useTWAEvent } from '@tonsolutions/analytics-react';
import { useState, useEffect, useContext, useRef, Dispatch, SetStateAction } from 'react';
import { useFormik } from 'formik';
import Iframe from 'react-iframe'
import * as yup from "yup";
import Header from "../../../components/basic/Header/Header";
import Slot from "../../../components/project/Slot/Slot";
import PaymentConfirmed from '../PaymentConfirmed/PaymentConfirmed';
import CirclePreloader from '../../../UI/Preloader/CirclePreloader';
import ContentExample from '../ContentExample/ContentExample';
import PaymentButton from '../PaymentButton/PaymentButton';
import HeadingInfo from '../HeadingInfo/HeadingInfo';
import OrderDetail from '../OrderDetail/OrderDetail';
import TotalPrice from '../TotalPrice/TotalPrice';
import Button from '../../../UI/Button/Button';
import TopicType from '../TopicType/TopicType';
import InfoBlock from '../InfoBlock/InfoBlock';
import Heading from '../Heading/Heading';
import Order from '../Order/Order';
import Task from '../Task/Task';
import OrderItem from '../Order/OrderItem';
import { CreatorContext, TgContext } from '../../../settings/context';
import { SERVER_URL_IMAGE } from '../../../settings/config';
import DAS_endpoints from '../../../settings/DAS_endpoints';
import Functions from '../../../tools/Functions';
import DAS_HTTP from '../../../tools/DAS_HTTP';
import { laungaugeRussian } from '../../../assets/project/laungaugeRussian';
import { laungaugeEnglisch } from '../../../assets/project/laungaugeEnglisch';
import styles from "./MainModule.module.scss";
import { local } from '../../../localization/local';
import { TRUE } from 'sass';
import user_bt_1 from "../../../../src/images/user_bt_1.png";
import user_bt_2 from "../../../../src/images/user_bt_2.png";
import Wallet from "../../../../src/images/Wallet.svg";
import Walletactivati from "../../../../src/images/Wallet-activati.svg";
import Home from "../../../../src/images/Home.svg";
import Homeactivati from "../../../../src/images/Home-activati.svg";
import Profile from "../../../../src/images/Profile.svg";
import Add from "../../../../src/images/add.png";
import Avatar from "../../../../src/images/Avatar.png";
import Item from "../../../../src/images/Item.png";
import Payment from "../../../../src/images/Payment.png";
import Avat from "../../../../src/images/Avat.png";
import { TxForm } from '../TxForm/TxForm';
import './App.css';
import TopUpBalance from '../BalanceTop/BalanceTop';

interface MainModuleInt {
    userId: number,
    stage: number,
    setStage: Dispatch<SetStateAction<number>>,
    toncoll: number,
    setTon: Dispatch<SetStateAction<number>>
    monetcoll: number,
    setMonetColl: Dispatch<SetStateAction<number>>
}


const truncateMiddle = (text, frontChars, backChars) => {
    if (text.length <= frontChars + backChars) {
      return text;
    }
    const start = text.slice(0, frontChars);
    const end = text.slice(text.length - backChars);
    return { start, end };
};

const apiUrl = 'https://apislot.pskovhack.ru/api/tablebest/';

export default function MainModule({ stage, setStage, toncoll, setTon, monetcoll, setMonetColl }: MainModuleInt) {
    const stageHandler = (stageIndex: number) => {
        setStage(stageIndex);
    }
    const [user, setUser] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [players, setPlayers] = useState([]);
    const [error, setError] = useState(null);
    const userFriendlyAddress = useTonAddress();
    
    const [infosum, setInfosum] = useState(null);
    const [date, setDate] = useState(null);
    const [type, setType] = useState(null);
    const [type_display, setTypeDisplay] = useState(null);

    const { tgApp } = useContext(TgContext);
    const language = tgApp?.initDataUnsafe?.user?.language_code || 'en';

    useEffect(() => {
        const fetchUser = async () => {
          try {
            const response = await fetch(`https://apislot.pskovhack.ru/api/user/`,{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_id: tgApp?.initDataUnsafe?.user?.id})
              });
            if (!response.ok) {
                if (tgApp?.initDataUnsafe?.user) {
                    const response = await fetch(`https://apislot.pskovhack.ru/api/usertelegrams/`,{
                        method: 'POST',
                        headers: {
                        'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({   
                            "user_tg_id":tgApp?.initDataUnsafe?.user?.id,
                            "username": tgApp?.initDataUnsafe?.user?.username,
                            "name": tgApp?.initDataUnsafe?.user?.first_name + " " + tgApp?.initDataUnsafe?.user?.last_name,
                        })
                    });
                    const data = await response.json();
                    setUser(data);
                }
                else{
                    const response = await fetch(`https://apislot.pskovhack.ru/api/user/`,{
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ user_id: "6881583462"})
                      });
                    const data = await response.json();
                    setUser(data);
                }
                
            }
            const data = await response.json();
            setUser(data);
          } catch (error) {
            setError(error.message);
          } 
        //   finally {
        //     setLoading(false);
        //   }
        };
    
        fetchUser();
      }, [tgApp]);


    useEffect(() => {
        const fetchTransactions = async () => {
          try {
            const response = await fetch(`https://apislot.pskovhack.ru/api/transactions/user/${user?.id}/`);
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setTransactions(data);
          } catch (error) {
            setError(error.message);
          }
        };
    
        fetchTransactions();
      }, [user?.id]);

    useEffect(() => {
        const fetchBestPlayers = async () => {
          try {
            const response = await fetch(apiUrl);
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setPlayers(data);
          } catch (error) {
            setError(error.message);
          }
        };
    
        fetchBestPlayers();
      }, []);

    useEffect(() => {
        if (stage === 2) {
            tgApp?.BackButton.show();
            tgApp?.BackButton.onClick(() => {
                stageHandler(1);
                // builder.track("Возврат обратно", {"stage": "details"});
                tgApp.BackButton.hide();
            });
        }
        if (stage === 3) {
            tgApp?.BackButton.show();
            tgApp?.BackButton.onClick(() => {
                stageHandler(1);
                // builder.track("Возврат обратно", {"stage": "confirm"});
                tgApp.BackButton.hide();
            });
        }
        if (stage === 4) {
            tgApp?.BackButton.show();
            tgApp?.BackButton.onClick(() => {
                stageHandler(3);
                tgApp.BackButton.hide();
            });
        }
        if (stage === 5) {
            tgApp?.BackButton.show();
            tgApp?.BackButton.onClick(() => {
                stageHandler(1);
                tgApp.BackButton.hide();
            });
        }
    }, [stage]);

    return (
        <section className={(Number(stage) === 3) ? styles["main-module__payment"] : ""}>
            
            {(stage === 0) && (
                <div className="grid-one center padding">
                    <CirclePreloader />
                </div>
            )}
            {(stage == 1) && (
                <>
                
                <Header user={user} setUser={setUser} image={user?.photo_url} setStage={setStage} stage={stage} toncoll={user?.ton_balance} setTon={setTon} monetcoll={user?.coin_balance} setMonetColl={setMonetColl} />
                <InfoBlock />
                <div className="grid-one center padding">
                
                    <div>
                    </div>
                    <div className={styles["main-module__block_game"]}>
                        <h2>
                            {(language == "en") && (
                                <>
                                Games
                                </>
                            )}
                            {(language == "ru") && (
                                <>
                                Игры
                                </>
                            )}
                        </h2>
                        <div className={styles["main-module__block_game_1"]}
                        onClick={() => stageHandler(2)}>
                            <h2>
                                
                                SLOT
                            </h2>
                            <h3>
                                Lore ipsum
                            </h3>
                        </div>
                    </div>
                    <div className={styles["main-module__block_table"]}>
                        <h2>
                            {(language == "en") && (
                                <>
                                Last bets
                                </>
                            )}
                            {(language == "ru") && (
                                <>
                                Лучшие игроки
                                </>
                            )}
                            
                        </h2>
                        <table>
                            <tr className={styles["main-module__block_table_th"]}>
                                <th>
                                    {(language == "en") && (
                                        <>
                                        Player
                                        </>
                                    )}
                                    {(language == "ru") && (
                                        <>
                                        Игрок
                                        </>
                                    )}
                                </th>
                                <th>
                                    {(language == "en") && (
                                        <>
                                        Time
                                        </>
                                    )}
                                    {(language == "ru") && (
                                        <>
                                        Время
                                        </>
                                    )}
                                </th>
                                <th> 
                                    {(language == "en") && (
                                        <>
                                        Bet
                                        </>
                                    )}
                                    {(language == "ru") && (
                                        <>
                                        Ставка
                                        </>
                                    )}
                                </th>
                                <th>
                                
                                    {(language == "en") && (
                                        <>
                                        Playout
                                        </>
                                    )}
                                    {(language == "ru") && (
                                        <>
                                        Выигрыш
                                        </>
                                    )}
                                </th>
                            </tr>
                            {players.map(player => {
                                const { start, end } = truncateMiddle(player.name, 5, 5);
                                return  (
                                    <tr className={styles["main-module__block_table_td"]}>
                                        <td className={styles["main-module__block_table_td_img"]}>
                                            <img src={player.image_url} alt="" />
                                            <p className="truncate-middle">
                                                {start}...<br />{end}
                                            </p>
                                        </td>
                                        <td>
                                            <p>{player.time}</p>
                                        </td>
                                        <td>
                                            <p>
                                            $ {player.bet}.00
                                            </p>
                                        </td>
                                        <td>
                                            <p className={styles["main-module__block_orange"]}>
                                               $ {player.playout}.00
                                            </p>
                                        </td>
                                    </tr>
                                )
                            }
                                
                                
                            )}
                        </table>
                        <div className={styles["main-module__block_footer"]}>
                            <div className={styles["main-module__block_footer_container"]}>
                                <img src={Wallet} alt="" onClick={() => stageHandler(3)} />
                                <img src={Homeactivati} alt="" onClick={() => stageHandler(1)} />
                                <img src={Profile} alt="" onClick={() => stageHandler(5)} />
                            </div>
                        </div>
                    </div>
                </div>
                </>
                
                
            )}
            {(stage == 2) && (
                <div className="grid-one center padding">
                    <Header user={user} setUser={setUser} setStage={setStage} image={user?.photo_url} stage={stage} toncoll={user?.ton_balance} setTon={setTon} monetcoll={user?.coin_balance} setMonetColl={setMonetColl}/>
                    <Slot userId={user.id} image={user?.photo_url} userbalance={user.coin_balance}/>
                    <div className={styles["main-module__block_footer"]}>
                        <div className={styles["main-module__block_footer_container"]}>
                            <img src={Wallet} alt="" onClick={() => stageHandler(3)} />
                            <img src={Homeactivati} alt="" onClick={() => stageHandler(1)} />
                            <img src={Profile} alt="" onClick={() => stageHandler(5)} />
                        </div>
                    </div>
                </div>
            )}
            {(stage == 3) && (
                <div className="grid-one center padding">
                    <div className="block_balance">
                        <h2>{(language == "en") && (
                                <>
                                Balance
                                </>
                            )}
                            {(language == "ru") && (
                                <>
                                Баланс
                                </>
                            )}</h2>
                        <div className="block_balance_container">
                            <h1>
                                 
                            </h1>
                            <p>
                                &nbsp;{user.ton_balance + user.coin_balance} 
                            </p>
                            {/* <img src={Add} alt="" onClick={() => 
                                {
                                    stageHandler(6);
                                }} /> */}
                        </div>
                    </div>
                    <div className="block_toncoin">
                        <div className="block_toncoin_left">
                            <div className="block_toncoin_image">
                                <img src={Avatar} alt="" />
                            </div>
                            <div>
                                <h2>Toncoin</h2>
                            </div>
                        </div>
                        <div className='block_toncoin_text'>
                            {user.ton_balance}
                        </div>
                    </div>
                    <div className="block_toncoin">
                        <div className="block_toncoin_left">
                            <div className="block_toncoin_image">
                                <img src={Avatar} alt="" />
                            </div>
                            <div>
                                <h2>Сoin</h2>
                            </div>
                        </div>
                        <div className='block_toncoin_text'>
                            {user.coin_balance}
                        </div>
                    </div>
                    <div className='block_balance_history'>
                        <h2>
                            {(language == "en") && (
                                <>
                                Transaction history
                                </>
                            )}
                            {(language == "ru") && (
                                <>
                                История транзакций
                                </>
                            )}
                        </h2>
                        <div className='block_balance_history_container'>
                            {transactions.map(transaction => (
                                <div className='block_balance_history_item' onClick={() => 
                                {
                                    stageHandler(4);
                                    setInfosum(transaction.summa);
                                    setDate(transaction.data_transaction);
                                    setType(transaction.transaction_type);
                                    setTypeDisplay(transaction.transaction_type_display);
                                }
                                
                                }>
                                    
                                    <div className='block_balance_history_wrapper'>
                                        <div className='block_balance_history_image'>
                                            <img src={Item} alt="" />
                                        </div>
                                        <div className='block_balance_history_block'>
                                            <h2>
                                            {transaction.transaction_type_display === 'Ставка' && (
                                                <>
                                                    {(language == "en") && (
                                                        <>
                                                        Bet
                                                        </>
                                                    )}
                                                    {(language == "ru") && (
                                                        <>
                                                        Ставка
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {transaction.transaction_type_display === 'Выигрыш' && (
                                                <>
                                                    {(language == "en") && (
                                                        <>
                                                        Playout
                                                        </>
                                                    )}
                                                    {(language == "ru") && (
                                                        <>
                                                       Выигрыш
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            </h2>
                                            <p>{transaction.data_transaction}</p>
                                        </div>
                                    </div>
                                    {transaction.transaction_type === 'WIN' && (
                                        <div className='block_balance_history_text'>
                                            <h2>+{transaction.summa} TON</h2>
                                            <p>
                                            {(language == "en") && (
                                                <>
                                                Received
                                                </>
                                            )}
                                            {(language == "ru") && (
                                                <>
                                                Получено
                                                </>
                                            )}
                                                
                                            </p>
                                        </div>
                                    )}
                                    {transaction.transaction_type === 'DEP' && (
                                        <div className='block_balance_history_text'>
                                            <h2>+{transaction.summa} TON</h2>
                                            <p>{(language == "en") && (
                                                <>
                                                Received
                                                </>
                                            )}
                                            {(language == "ru") && (
                                                <>
                                                Получено
                                                </>
                                            )}</p>
                                        </div>
                                    )}
                                    {transaction.transaction_type === 'BET' && (
                                        <div className='block_balance_history_text text_white'>
                                            <h2>-{transaction.summa} TON</h2>
                                            <p>
                                            {(language == "en") && (
                                                <>
                                                Shipped
                                                </>
                                            )}
                                            {(language == "ru") && (
                                                <>
                                                Отправлено
                                                </>
                                            )}
                                            </p>
                                        </div>
                                    )}
                                    {transaction.transaction_type === 'SENT' && (
                                        <div className='block_balance_history_text'>
                                            <h2>+{transaction.summa} TON</h2>
                                            <p>{(language == "en") && (
                                                <>
                                                Received
                                                </>
                                            )}
                                            {(language == "ru") && (
                                                <>
                                                Получено
                                                </>
                                            )}</p>
                                        </div>
                                    )}
                                    
                            </div>
                            ))}
                        </div>
                    </div>
                    
                </div>
                
            )}
            {(stage == 4) && (
                <div className="grid-one center padding">
                    <div className='block_payment_wrapp'>
                        <div className='block_payment_header'>
                            <img src={Payment} alt="" />
                            <h2>{type_display}</h2> 
                        </div>
                        {type === 'WIN' && (
                            <div className='block_payment_sum'>
                                <h2>+{infosum} Ton</h2>
                            </div>
                        )}
                        {type === 'DEP' && (
                            <div className='block_payment_sum'>
                                <h2>+{infosum} Ton</h2>
                            </div>
                        )}
                        {type === 'BET' && (
                            <div className='block_payment_sum text_white'>
                                <h2>-{infosum} Ton</h2>
                            </div>
                        )}
                        {type === 'SENT' && (
                            <div className='block_payment_sum'>
                                <h2>+{infosum} Ton</h2>
                            </div>
                        )}
                        <div className='block_payment_status'>
                            <h3>{date}</h3>
                        </div>
                    </div>
                    <div className='block_payment_block_status'>
                        <h3>
                            Статус
                        </h3>
                        {type === 'WIN' && (
                            <h4>
                                
                                {(language == "en") && (
                                    <>
                                    Introduced
                                    </>
                                )}
                                {(language == "ru") && (
                                    <>
                                    Внесено
                                    </>
                                )}
                            </h4>
                        )}
                        {type === 'DEP' && (
                            <h4>
                                
                                {(language == "en") && (
                                    <>
                                    Paid
                                    </>
                                )}
                                {(language == "ru") && (
                                    <>
                                    Выплачено
                                    </>
                                )}
                            </h4>
                        )}
                        {type === 'BET' && (
                            <h4>
                                
                                {(language == "en") && (
                                    <>
                                    Paid
                                    </>
                                )}
                                {(language == "ru") && (
                                    <>
                                    Оплачено
                                    </>
                                )}
                            </h4>
                        )}
                        {type === 'SENT' && (
                            <h4>
                                
                                {(language == "en") && (
                                    <>
                                    Sent
                                    </>
                                )}
                                {(language == "ru") && (
                                    <>
                                    Отправлено
                                    </>
                                )}
                            </h4>
                        )}
                        
                    </div>
                </div>
                
            )}
            {(stage == 5) && (
                <div className="grid-one center padding">
                    <div className='block_profile'>
                        <div className='block_profile_image'>
                            <img src={user.photo_url} alt="" />
                        </div>
                        <div className='block_profile_info'>
                            <h2>{user.name}</h2>
                            {/* <h3>Пользователь с ...</h3> */}
                        </div>
                    </div>
                    <div className='block_balance_history'>
                        <h2>
                            
                            {(language == "en") && (
                                <>
                                Transactions
                                </>
                            )}
                            {(language == "ru") && (
                                <>
                                Транзакции
                                </>
                            )}
                        </h2>
                        <div className='block_balance_history_container'>
                            {transactions.map(transaction => (
                                <div className='block_balance_history_item' onClick={() => 
                                {
                                    stageHandler(4);
                                    setInfosum(transaction.summa);
                                    setDate(transaction.data_transaction);
                                    setType(transaction.transaction_type);
                                    setTypeDisplay(transaction.transaction_type_display);
                                }
                                
                                }>
                                    
                                    <div className='block_balance_history_wrapper'>
                                        <div className='block_balance_history_image'>
                                            <img src={Item} alt="" />
                                        </div>
                                        <div className='block_balance_history_block'>
                                            <h2>{transaction.transaction_type_display}</h2>
                                            <p>{transaction.data_transaction}</p>
                                        </div>
                                    </div>
                                    {transaction.transaction_type === 'WIN' && (
                                        <div className='block_balance_history_text'>
                                            <h2>+{transaction.summa} TON</h2>
                                            <p>
                                            {(language == "en") && (
                                                <>
                                                Received
                                                </>
                                            )}
                                            {(language == "ru") && (
                                                <>
                                                Получено
                                                </>
                                            )}
                                            </p>
                                        </div>
                                    )}
                                    {transaction.transaction_type === 'DEP' && (
                                        <div className='block_balance_history_text'>
                                            <h2>+{transaction.summa} TON</h2>
                                            <p>{(language == "en") && (
                                                <>
                                                Received
                                                </>
                                            )}
                                            {(language == "ru") && (
                                                <>
                                                Получено
                                                </>
                                            )}</p>
                                        </div>
                                    )}
                                    {transaction.transaction_type === 'BET' && (
                                        <div className='block_balance_history_text text_white'>
                                            <h2>-{transaction.summa} TON</h2>
                                            <p>{(language == "en") && (
                                                <>
                                                Sent
                                                </>
                                            )}
                                            {(language == "ru") && (
                                                <>
                                                Отправлено
                                                </>
                                            )}</p>
                                        </div>
                                    )}
                                    {transaction.transaction_type === 'SENT' && (
                                        <div className='block_balance_history_text'>
                                            <h2>+{transaction.summa} TON</h2>
                                            <p>{(language == "en") && (
                                                <>
                                                Received
                                                </>
                                            )}
                                            {(language == "ru") && (
                                                <>
                                                Получено
                                                </>
                                            )}</p>
                                        </div>
                                    )}
                                    
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
                
            )}
            {/* {(stage = 6) && (
                <TopUpBalance />
            )} */}
        </section>
    )
}
