import './Slot.css';
import React, { useState, useEffect, useContext, useRef, Dispatch, SetStateAction } from 'react';
import { CreatorContext, TgContext } from '../../../settings/context';
import elem_1 from "../../../images/elem_1.png";
import elem_2 from "../../../images/elem_2.png";
import elem_3 from "../../../images/elem_3.png";
import elem_4 from "../../../images/elem_4.png";
import elem_5 from "../../../images/elem_5.png";
import elem_6 from "../../../images/elem_6.png";
import elem_7 from "../../../images/elem_7.png";
import elem_8 from "../../../images/elem_8.png";
import track from '../../project/Slot/slot.mp3';
import track_win from '../../project/Slot/slotmachine.mp3';

const App = ({userId, image, userbalance,}) => {
  const [spin, setSpin] = useState(false);
  const [results, setResults] = useState([]);
  const [price, setPrice] = useState();
  const [input, setInput] = useState();
  const [realBet, setRealBet] = useState();
  const [jackpot, setJackpot] = useState(0);
  const [balance, setBalance] = useState(userbalance);
  const [balanceset, setBalanceset] = useState(0);
  const [auto, setAuto] = useState(false);
  const [autocoll, setAutoColl] = useState(0);
  const [volatility, setVolatility] = useState("standard")
  const audioRef = useRef(null);
  const audioRef2 = useRef(null);
  const { tgApp } = useContext(TgContext);
  const language = tgApp?.initDataUnsafe?.user?.language_code || 'en';
  const symbolsMap = {
    'Seven': elem_1,
    'Watermelon': elem_2,
    'Plum': elem_3,
    'Mandarin': elem_4,
    'Lemon': elem_5,
    'Grape': elem_6,
    'Cherry': elem_7,
    'BONUS': elem_8
  };

//   useEffect(() => {
//     if (results.length > 0) {
//       win();
//     }
//   }, [results]);
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  const play = async () => {
    if (input <= balance && input >= 1) {
      setRealBet(input);
      setSpin(true);
      setResults([]);
      setBalance(balance - input);
      setJackpot(jackpot);

      try {
        if(auto==true){
          const response = await fetch('https://apislot.pskovhack.ru/api/auto-bet/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              user: userId, // замените на актуальный идентификатор пользователя
              bet: input,
              volatility: volatility,
              image: image,
              auto_amount:autocoll // или другую нужную волатильность
            })
          });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          audioRef.current.currentTime = 0;
          audioRef.current.play();
          await delay(8000);
          audioRef.current.pause();
          setResults(data.results);
          if (data.win_amount > 0){
            audioRef2.current.play();
          }
          setPrice(data.win_amount);
          setJackpot(data.win_amount);
          setBalanceset(balanceset + data.win_amount - input*autocoll); // обновляем баланс с учетом выигрыша
        }
        else{
          const response = await fetch('https://apislot.pskovhack.ru/api/place-bet/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              user: userId, // замените на актуальный идентификатор пользователя
              bet: input,
              volatility: volatility,
              image: image // или другую нужную волатильность
            })
          });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          audioRef.current.currentTime = 0;
          audioRef.current.play();
          await delay(8000);
          audioRef.current.pause();
          setResults(data.results);
          if (data.win_amount > 0){
            audioRef2.current.play();
          }
          setPrice(data.win_amount);
          setJackpot(data.win_amount);
          setBalanceset(balanceset + data.win_amount - input); // обновляем баланс с учетом выигрыша
        }
        

        
      } catch (error) {
        console.error('Error placing bet:', error);
      }
    } else {
      setPrice(10);
    }
  };

  useEffect(() => {
    if (input > 100){
        setInput(100);
    }
  }, [input]);
  const renderRow = (rowIndex) => {
    if (!spin) {
      return (
        <>
          <div className="ringEnd"><img src={symbolsMap['Plum']} /></div>
          <div className="ringEnd"><img src={symbolsMap['BONUS']} /></div>
          <div className="ringEnd"><img src={symbolsMap['BONUS']} /></div>
          <div className="ringEnd"><img src={symbolsMap['Cherry']} /></div>
        </>
      );
    } else if (spin && results.length === 0) {
      return (
        <>
          <div className="ringMoving"><img src={symbolsMap['Seven']} /></div>
          <div className="ringMoving"><img src={symbolsMap['BONUS']} /></div>
          <div className="ringMoving"><img src={symbolsMap['BONUS']} /></div>
          <div className="ringMoving"><img src={symbolsMap['Cherry']} /></div>
        </>
      );
    } else if (results.length > 0) {
      return results.slice(rowIndex * 3, rowIndex * 3 + 3).map((symbol, index) => (
        <div key={index} className="ringEnd"><img src={symbolsMap[symbol]} /></div>
      ));
    }
  };

  const numChecker = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]+$/;
    if (value.match(regex) && parseInt(value) >= 0 || value === "") {
      setInput(value);
    }
  };
  const autoChecker = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]+$/;
    if (value.match(regex) && parseInt(value) >= 0 || value === "") {
      setAutoColl(value);
    }
  };

  const premio = () => {
    if (price === 1 && results.length > 0) {
      return (
        <p className="priceInd">{"🍇 X15 You've won " + (realBet * 15) + "€!"}</p>
      );
    } else if (price === 2 && results.length > 0) {
      return (
        <p className="priceInd">{"🍊 X20 You've won " + (realBet * 20) + "€!"}</p>
      );
    } else if (price === 3 && results.length > 0) {
      return (
        <p className="priceInd">{"🥭 X25 You've won " + (realBet * 25) + "€!"}</p>
      );
    } else if (price === 4 && results.length > 0) {
      return (
        <p className="priceInd">{"🍓 Jackpot! You've won: " + (jackpot) + "€!"}</p>
      );
    } else if (price === 0 && results.length > 0) {
      return (
        <p className="priceInd">😧 ¡So close! But no luck...</p>
      );
    } else if (price === 10) {
      return (
        <p className="priceInd">🥶 <span style={{ color: `red` }}>Not enough funds</span> </p>
      );
    }
  };

  return (
    <div className="fullSlot">
      <div className="slot">
        <div className="row">
          {renderRow(0)}
          {spin==true && (
            <>
            
            <div className="ringEnd"><img src={symbolsMap['elem_3']} /></div>
            <div className="ringEnd"><img src={symbolsMap[results[0]]} /></div>
            <div className="ringEnd"><img src={symbolsMap[results[1]]} /></div>
            <div className="ringEnd"><img src={symbolsMap[results[2]]} /></div>
            </>
          )}
        </div>
        <div className="row">
          {renderRow(1)}
          {spin==true && (
            <>
            
            <div className="ringEnd"><img src={symbolsMap['elem_3']} /></div>
            <div className="ringEnd"><img src={symbolsMap[results[3]]} /></div>
            <div className="ringEnd"><img src={symbolsMap[results[4]]} /></div>
            <div className="ringEnd"><img src={symbolsMap[results[5]]} /></div>
            </>
          )}
        </div>
        <div className="row">
          {renderRow(2)}
          {spin==true && (
            <>
            
            <div className="ringEnd"><img src={symbolsMap['elem_3']} /></div>
            <div className="ringEnd"><img src={symbolsMap[results[6]]} /></div>
            <div className="ringEnd"><img src={symbolsMap[results[7]]} /></div>
            <div className="ringEnd"><img src={symbolsMap[results[8]]} /></div>
            </>
          )}
        </div>
      </div>
      <div className="slotFoot">
        <div className="slotTab">
          <h3 className={`${auto==true ? '' : 'active'}`} onClick={() => setAuto(false)}>{(language == "en") && (
                            <>
                            Manual
                            </>
                        )}
                        {(language == "ru") && (
                            <>
                            Инструкция
                            </>
                        )}</h3>
          <h3 className={`${auto==true ? 'active' : ''}`} onClick={() => setAuto(true)}>{(language == "en") && (
                            <>
                            Auto
                            </>
                        )}
                        {(language == "ru") && (
                            <>
                            Авто
                            </>
                        )}</h3>
        </div>
        {auto==true && (
          <div className='slotInputSum'>
          <div className='slotSum'>
            <p>
                {(language == "en") && (
                    <>
                    AUTO:
                    </>
                )}
                {(language == "ru") && (
                    <>
                    АВТО:
                    </>
                )}
            </p>
            <p>
              {autocoll}
            </p>
          </div>
          <input value={autocoll} type="number" onChange={(e) => autoChecker(e)} className="betInput" placeholder="0"></input>
        </div>
        )}
        
        <div className='slotInputSum'>
          <div className='slotSum'>
            <p>
              
              {(language == "en") && (
                    <>
                    Total
                    </>
              )}
              {(language == "ru") && (
                  <>
                  Всего
                  </>
              )}
            </p>
            <p>
              {balanceset} TON
            </p>
          </div>
          <input value={input} type="number" onChange={(e) => numChecker(e)} className="betInput" placeholder="0"></input>
        </div>

        <div className='slotValCont'>
          <button onClick={() => setInput(1)} className="slotValContButton">{(language == "en") && (
                    <>
                    MIN
                    </>
              )}
              {(language == "ru") && (
                  <>
                  МИН
                  </>
              )}</button>
          <button onClick={() => setInput(input*2)} className="slotValContButton">2X</button>
          <button onClick={() => setInput(100)} className="slotValContButton">{(language == "en") && (
                    <>
                    MAX
                    </>
              )}
              {(language == "ru") && (
                  <>
                  МАКС
                  </>
              )}</button>
        </div>
        <div className='slotVal'>
          <div className='slotValS'>
            <p>
              
              {(language == "en") && (
                    <>
                    Volatility
                    </>
              )}
              {(language == "ru") && (
                  <>
                  Волатильность
                  </>
              )}
            </p>
            
                {volatility === 'low' && (
                   <p> {(language == "en") && (
                    <>
                    MIN
                    </>
                  )}
                  {(language == "ru") && (
                      <>
                      МИН
                      </>
                  )}</p>
                )}
                {volatility === 'standard' && (
                    <p> {(language == "en") && (
                      <>
                      Middle
                      </>
                    )}
                    {(language == "ru") && (
                        <>
                        Средняя
                        </>
                    )} </p>
                )}
                {volatility === 'high' && (
                    <p> {(language == "en") && (
                      <>
                      MAX
                      </>
                    )}
                    {(language == "ru") && (
                        <>
                        МАКС
                        </>
                    )} </p>
                )}

          </div>
          <div className='slotValCont'>

            <button onClick={() => {
              setVolatility("low");
            }} className={`slotValContButton ${volatility=="low" ? 'test' : ''}`}>{(language == "en") && (
              <>
              MIN
              </>
            )}
            {(language == "ru") && (
                <>
                МИН
                </>
            )}</button>
            <button onClick={() => {
                setVolatility("standard");
            }} className={`slotValContButton ${volatility=="standard" ? 'test' : ''}`}>{(language == "en") && (
              <>
              Middle
              </>
            )}
            {(language == "ru") && (
                <>
                Средняя
                </>
            )}</button>
            <button onClick={() => {
                setVolatility("high");
            }} className={`slotValContButton ${volatility=="high" ? 'test' : ''}`}>{(language == "en") && (
              <>
              MAX
              </>
            )}
            {(language == "ru") && (
                <>
                МАКС
                </>
            )}</button>
          </div>
        </div>
      </div>
      <button className="spinButton" onClick={() => play()}>{(language == "en") && (
              <>
              To put
              </>
            )}
            {(language == "ru") && (
                <>
                Отправить
                </>
            )}</button>
      <h1 className="price">{"Winning "} <span className='textColor'>{jackpot}</span></h1>
      <audio ref={audioRef} src={track} />
      <audio ref={audioRef2} src={track_win} />
    </div>
    
  );
};

export default App;
