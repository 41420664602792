import React from 'react';
import {TonConnectButton} from "@tonconnect/ui-react";
import {SendTransactionRequest, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import { useTonAddress } from '@tonconnect/ui-react';
import { HeaderInt } from '../../../settings/interfaces';
import { useState, useEffect, useContext, useRef, Dispatch, SetStateAction } from 'react';
import logo from "../../../images/logo.png";
import styles from "./Header.module.scss";
import user_bt_2 from "../../../images/user_bt_2.png";
import ton_image from "../../../images/ton_image.png";
import payment_two from "../../../images/payment_two.png";


interface MainModuleInt {
    stage: number,
    setStage: Dispatch<SetStateAction<number>>,
    toncoll: number,
    setTon: Dispatch<SetStateAction<number>>
    monetcoll: number,
    setMonetColl: Dispatch<SetStateAction<number>>,
	image: string,
	user: any,
	setUser: Dispatch<SetStateAction<any[]>>
}



export default function Header({ user,setUser, stage, setStage, toncoll, setTon, monetcoll, setMonetColl, image }: MainModuleInt){

	const stageHandler = (stageIndex: number) => {
        setStage(stageIndex);
    }
	const userFriendlyAddress = useTonAddress();
	const wallet = useTonWallet();


	useEffect(() => {
		if (userFriendlyAddress && !user?.ton_adress) {
		  const updateUser = async () => {

			try {
			  const response = await fetch(`https://apislot.pskovhack.ru/api/usertelegrams/${user?.id}/`, {
				method: 'PATCH',
				headers: {
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({ ton_adress: userFriendlyAddress })
			  });
			  if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			  }
			  const data = await response.json();
			  setUser(data);
			} catch (error) {
			  console.log("");
			}
		  };
	
		  updateUser();
		}
	  }, [user?.id]);

	return(
		<header className={`${styles["DAS-header"]}`}>
			<img src={logo} alt="circle" />
			
			<div className={`${styles["DAS-header__container"]}`} >
				<div className={`${styles["DAS-header__payments_container"]}`}>
					<TonConnectButton />
					<div className={`${styles["DAS-header__payments"]}`}>
						<div className={`${styles["DAS-header__payment"]}`}>
							<img src={ton_image} alt="" />
							<p>{toncoll}</p>
						</div>
						<div className={`${styles["DAS-header__payment"]}`}>
							<img src={payment_two} alt="" />
							<p>{monetcoll}</p>
						</div>
					</div>
				</div>
				
				<img src={image} alt="" />
			</div>
		</header>
	)
}
