import React from 'react';
import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client'
import App from './App';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import './index.css';

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)
root.render(
	
	<App />
);